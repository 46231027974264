import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

function Logo({ className }) {
  return (
    <StaticImage
      className={className}
      src="./logo.png"
      height={48}
      width={48}
      alt="logo"
    />
  )
}

export default Logo
