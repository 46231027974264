import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          appStoreLink
          facebookLink
          twitterLink
        }
      }
    }
  `)

  return (
    <div className="relative">
      <Header appStoreLink={data.site.siteMetadata.appStoreLink} />
      <main className="nav-offset layout-height">{props.children}</main>
      <Footer
        facebookLink={data.site.siteMetadata.facebookLink}
        twitterLink={data.site.siteMetadata.twitterLink}
        appStoreLink={data.site.siteMetadata.appStoreLink}
      />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
